/* Loading.css */
@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loading-balls {
    display: flex;
    gap: 8px;
  }
  
  .loading-balls div {
    width: 12px;
    height: 12px;
    background-color: #ffffff;
    border-radius: 50%;
    animation: bounce 0.6s infinite alternate;
  }
  
  .loading-balls div:nth-child(1) {
    animation-delay: 0s;
  }
  
  .loading-balls div:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .loading-balls div:nth-child(3) {
    animation-delay: 0.4s;
  }
  