
.overview-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .overview-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .accordion {
    overflow: hidden;
    color: black;
  }  
    
  .accordion-item input[type="checkbox"] {
    display: none;
  }
  
  .accordion-item label {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 10px 15px;
    background: #f7f7f7;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease;
  }
  
  .accordion-item-points{
    display: flex;
    flex-direction: row;
}

  .accordion-item label:hover {
    background: #eaeaea;
  }
  
  .accordion-item .accordion-content {
    display: none;
    padding: 10px 15px;
    background: #fff;
  }
  
  .accordion-item input[type="checkbox"]:checked ~ .accordion-content {
    display: block;
  }
  
  .stage-section {
    margin-bottom: 20px;
  }
  
  .stage-section h2 {
    margin-top: 20px;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
  }
  
  .accordion-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .accordion-content ul li {
    padding: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;
  }
  
  .accordion-content ul li:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
  
  .correct-result {
    background-color: #d4edda;
  }
  
  .incorrect-result {
    background-color: #f8d7da;
  }
  
  .todays-matches{
    color: white;
  }

  .loading{
    color: white;

  }

  .stage-section ul {
  list-style: none;
  padding: 0;
}

.stage-section li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.team-name {
  flex-grow: 1;
}

.team-group {
  text-align: right;
}
.form-indicator {
  display: flex;
  gap: 4px;
  align-items: center; /* Center the dots vertically */
  justify-content: center; /* Center the dots horizontally */
}

.form-indicator span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.form-indicator .correct {
  background-color: green;
}

.form-indicator .incorrect {
  background-color: red;
}

.form-indicator .no-prediction {
  background-color: gray;
}

.prediction-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  color: white;
}

.next-round-predictions{
  color: 'white';
  margin-top: '20px';
}

.prediction-table td {
  padding: 8px 15px;
  border: 1px solid #ddd;
}

.prediction-table .user-names {
  padding-left: 20px; /* Adds more space between the first and second column */
}

.stage-section {
  margin-bottom: 20px;
}

.stage-section h2 {
  font-size: 1.2em;
  color: white;

  margin-bottom: 10px;
}

.rules-section {
  margin-top: 30px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: white;
}

.rules-section h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.rules-section p {
  margin-bottom: 10px;
}

.rules-section ol {
  padding-left: 20px;
}

.rules-section ol li {
  margin-bottom: 5px;
}

.eliminated-team {
  background-color: rgba(255, 0, 0, 0.4);
}